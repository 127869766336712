import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { GlobalContext } from '../context/GlobalContext'
import { Link } from 'gatsby'

const Tarifs = () => {
  const [data] = useContext(GlobalContext)
  const [allTarifs] = useState(data.allDatoCmsTarif)
  return (
    <TarifsWrapper>
      <h2>Les Tarifs</h2>
      <CardsWrapper>
        {allTarifs.edges.map(({ node: tarif }) => {
          return (
            <CardWrapper key={tarif.id}>
              <h4>{tarif.title}</h4>
              <p>{tarif.prix}</p>
              <span>{tarif.description}</span>
              {tarif.button !== false ? (
                <Link to={tarif.chemin}>
                  <button className="btn">En savoir plus</button>
                </Link>
              ) : (
                <></>
              )}
            </CardWrapper>
          )
        })}
      </CardsWrapper>
      <p>
        Sauf cas exceptionnel, toute séance non décommandée 24h à l'avance sera
        considérée comme due.
        <br />
        Merci de votre compréhension
      </p>
      <p>* Paiement en plusieurs fois disponible</p>
    </TarifsWrapper>
  )
}

const TarifsWrapper = styled.div`
  margin: 5rem 0;
  padding: 0 3rem;
  width: 100%;
  h2 {
    text-align: center;
    margin: 5rem 0 0 0;
    font-family: 'Roboto Condensed', sans-serif;
  }
  p {
    margin: 3rem 0;
    font-size: 0.8rem;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
  }
`
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
const CardWrapper = styled.div`
  position: relative;
  width: 16rem;
  text-align: center;
  min-height: 20rem;
  height: auto;
  padding: 2rem;
  margin-top: 3rem;
  box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.02),
    0 2.2px 5.3px rgba(0, 0, 0, 0.028), 0 4.1px 10px rgba(0, 0, 0, 0.035),
    0 7.4px 17.9px rgba(0, 0, 0, 0.042), 0 13.8px 33.4px rgba(0, 0, 0, 0.05),
    0 33px 80px rgba(0, 0, 0, 0.07);

  p {
    display: block;
    font-size: 3rem;
    margin: 5rem 0;
    font-family: 'Roboto Condensed', sans-serif;
  }

  h4 {
    font-family: 'Roboto Condensed', sans-serif;
  }

  span {
    font-family: 'Roboto Condensed', sans-serif;
  }

  .btn {
    width: 80%;
    height: 3rem;
    margin: 2rem auto;
    border-radius: 0.5rem;
    border: 0.1rem solid #333;
    color: #333;
    background: #fff;
    transition: 0.5s ease-in-out;
    outline: none;
    cursor: pointer;
    a {
      text-decoration: none;
      color: #333;
    }
  }
  .btn:hover {
    color: #fff;
    background: #333;
    a {
      text-decoration: none;
      color: #fff;
    }
  }
`

export default Tarifs
