import React from 'react'
import styled from 'styled-components'
import background from '../images/BackHome1.jpeg'



const Home = () => {
    return (
        <HomeWrapper as='section'>
         <BackgroundWrapper/>
         <h1><strong>Cours particuliers de piano à domicile sur Toulon et ses environs.</strong></h1>
         </HomeWrapper>
    )
}

const HomeWrapper = styled.div`
color:#fff;
h1{
    width: 70%;
    position:absolute;
    top: 55%;
    left:3rem;;
    font-size: 3.3rem;
    font-family: "Lato", sans-serif;
    line-height: 4rem;
    opacity: 0.7;
}


@media screen and (max-width: 700px){
    h1{
   top: 30%;
    font-size: 3rem;
}
}
`
const BackgroundWrapper = styled.div`
background: url(${background});
background-size: cover;
min-height: 105vh;
margin-top: -9rem;
filter: brightness(70%);
z-index: 1;

@media screen and (max-width: 769px){
    margin-left: -20rem;
}
    @media screen and (max-width: 426px){
    margin-left: -22rem;
    }


    @media screen and (max-width: 375px){
        min-height: 100vh;
        margin-left: -25rem;

    }
`
export default Home
