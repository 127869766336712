import React, { createContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsTarif(sort: { order: ASC, fields: id }) {
        edges {
          node {
            id
            title
            description
            prix
            button
            chemin
          }
        }
      }
      allDatoCmsDebuterLePiano {
        edges {
          node {
            title
            description
            picture {
              url
            }
          }
        }
      }
      allDatoCmsMethode {
        edges {
          node {
            title
            description
            picture {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <GlobalContext.Provider value={[data]}>{children}</GlobalContext.Provider>
  )
}
