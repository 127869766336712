import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { GlobalContext } from '../context/GlobalContext'
import ReactMarkdown from 'react-markdown'

const Method = () => {
  const [data] = useContext(GlobalContext)
  const [method] = useState(data.allDatoCmsMethode.edges[0].node)
  return (
    <>
      <h2
        style={{
          textAlign: 'center',
          margin: '5rem 0',
          fontFamily: "'Roboto Condensed', sans-serif",
        }}
      >
        {method.title}
      </h2>
      <MethodWrapper as="article">
        <ReactMarkdown className="textMethod" source={method.description} />
        <img src={method.picture.url} alt="Piano lancelot" />
      </MethodWrapper>
    </>
  )
}

const MethodWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  height: auto;
  justify-content: center;
  margin: 5rem 0;

  .textMethod {
    padding: 0 2rem;
    line-height: 2rem;

    @media screen and (max-width: 600px) {
      width: 90%;
    }
  }
  img {
    width: 15rem;
    height: auto;
  }
`

export default Method
