import React,{ useState, useContext} from 'react'
import styled from 'styled-components'
import { GlobalContext } from '../context/GlobalContext'
import ReactMarkdown from "react-markdown"

const LearnPiano = () => {
    const [data] = useContext(GlobalContext)
    const [learn] = useState(data.allDatoCmsDebuterLePiano.edges[0].node)

    return (
        <LearnWrapper as='article'>
            <h2>
                {learn.title}
            </h2>
            <ReactMarkdown source={learn.description} />
            <img src={learn.picture.url} alt='Piano Lancelot'/>
        </LearnWrapper>
    )
}

const LearnWrapper = styled.div`
margin: 5rem 0;
padding: 0 3rem;
width: 100%;
min-height: 80vh;
display:flex;
flex-direction: column;
h2{
    text-align:center;
    margin: 4rem 0;
    font-family: 'Roboto Condensed', sans-serif;
}
img{
    align-self: center;
    margin: 2rem 0;
    width: 20rem;
    border-radius: 5%;
    filter: brightness(0.8)
}
`

export default LearnPiano
