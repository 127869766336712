import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/Home"
import LearnPiano from "../components/LearnPiano"
import Tarifs from "../components/Tarifs"
import Method from "../components/Method"
import LogoW from "../images/Lancelot PianoWH.png"
import { GlobalProvider } from "../context/GlobalContext"

const IndexPage = () => (
  <GlobalProvider>
    <Layout logo={LogoW} color="#fff" menu="Contact" lien="/Contact">
      <SEO title="Cours de Piano. Cours particuliers de piano à domicile sur Toulon et ses environs. Tout niveau, enfants-ados-adultes | Accueil" />
      <Home />
      <LearnPiano />
      <Tarifs />
      <Method />
    </Layout>
  </GlobalProvider>
)

export default IndexPage
